<template>
  <div
    class="autostrategy-input"
    :class="classList"
  >
    <div
      v-if="!inputItem.notFormInput"
      class="autostrategy-input__container"
    >
      <div class="autostrategy-input__label-container">
        <div class="autostrategy-input__label">
          <span class="autostrategy-input__label-text">
            {{ inputItem.name }}
          </span>

          <MHelp
            v-if="inputItem.hintText"
            width="224px"
            placement="right"
            show-on="hover"
            class="autostrategy-input__hint"
          >
            {{ inputItem.hintText }}
          </MHelp>
        </div>

        <span
          v-if="inputItem.description && controlType === 'switcher'"
          class="autostrategy-input__label-description"
        >
          {{ inputItem.description }}
        </span>
      </div>

      <div class="autostrategy-input__input">
        <MNumericInput
          v-if="controlType === 'numeric'"
          :value="value"
          :vat="vatIfNeeded"
          :type="inputType"
          :min-with-vat="inputItem.minWithVat"
          allow-clear-input
          :minimum-value="customMinValue"
          :maximum-value="customMaxValue"
          :placeholder="inputItem.placeholder"
          :allow-decimal-padding="isCurrencyWb ? false : undefined"
          :decimal-places="isCurrencyWb ? 0 : undefined"
          @input="handleInput"
        />
        <MSelectControl
          v-if="controlType === 'select'"
          :choices="selectChoices"
          :value="value"
          id-attr="id"
          text-attr="name"
          allow-width-overflow
          is-in-modal
          :placeholder="inputItem.placeholder"
          @input="handleInput"
        />
        <MInput
          v-if="controlType === 'string'"
          :value="value"
          :placeholder="inputItem.placeholder"
          @input="handleInput"
        />
        <MTokenInput
          v-if="controlType === 'sequence'"
          :value="value"
          :placeholder="inputItem.placeholder"
          size="m"
          :unique="true"
          :paste-token-separator="/, ?|\n/g"
          :max-items="inputItem.maxItems"
          :warning-max-items="inputItem.warningMaxItems"
          :is-need-show-text-warning-max-items="inputItem.isNeedShowTextWarningMaxItems"
          @input="handleInput"
        />
        <Switcher
          v-if="controlType === 'switcher'"
          :state="Boolean(value)"
          label-position="right"
          @switched="handleInput"
        />
      </div>
    </div>

    <NotifyCard
      v-if="inputItem.notifyText"
      noty-type="orange"
      size="small"
      :is-floating-card="false"
      class="autostrategy-input__notify"
      :class="{'autostrategy-input__notify-not-with-input': inputItem.notFormInput}"
    >
      {{ inputItem.notifyText }}
    </NotifyCard>
  </div>
</template>

<script>
  import MHelp from 'modules/common/components/MHelp';
  import MNumericInput from 'legacy/modules/common/components/MNumericInput/MNumericInput';
  import {MSelectControl} from '@marilyn/marilyn-ui';
  import NotifyCard from 'components/common/NotifyCard.vue';
  import {mapGetters} from 'vuex';
  import MTokenInput from 'legacy/modules/common/components/MTokenInput';
  import Switcher from 'legacy/modules/common/components/Switcher';
  import {getConnectionByName} from 'utils/seller/sellerConnectionData';

  const SELECT_TYPES = ['time', 'choices'];

  export default {
    name: 'SellerAutostrategyInput',
    components: {
      NotifyCard,
      MNumericInput,
      MHelp,
      MTokenInput,
      MSelectControl,
      Switcher
    },
    props: {
      inputItem: {
        type: Object,
        required: true
      },
      value: {
        type: [Number, String, Array, Boolean],
        default: null
      },
      channelId: {
        type: [Number, String],
        required: true
      }
    },
    computed: {
      ...mapGetters('sellerUser', {
        vat: 'vatDivider'
      }),
      isCurrencyWb () {
        return this.inputType === 'currency' && Number(this.channelId) === getConnectionByName('wildberries').channelId;
      },
      vatIfNeeded () {
        if (this.inputType === 'currency') {
          return this.vat;
        }
        return false;
      },
      inputType () {
        return this.inputItem.type;
      },
      controlType () {
        if (this.inputType === 'string') {
          return 'string';
        } else if (SELECT_TYPES.includes(this.inputType)) {
          return 'select';
        } else if (this.inputType === 'string_list') {
          return 'sequence';
        } else if (this.inputType === 'boolean') {
          return 'switcher';
        }
        return 'numeric';
      },
      selectChoices () {
        if (this.inputType === 'time') {
          return Array.from(Array(24)).map((i, x) => x).
            map(i => ({
              id: i,
              name: `${i.toString().padStart(2, '0')}:00`
            }));
        } else if (this.inputType === 'choices') {
          return this.inputItem.args.choices;
        }
        return [];
      },
      customMinValue () {
        return this.inputItem.args?.min ?? null;
      },
      customMaxValue () {
        return this.inputItem.args?.max ?? null;
      },
      classList () {
        const classList = [];

        if (this.inputItem.sizeContainer) {
          classList.push(this.inputItem.sizeContainer);
        }

        if (this.controlType === 'switcher') {
          classList.push('__switcher');
        }

        return classList;
      }
    },
    methods: {
      handleInput (newValue) {
        this.$emit('change-value', {
          keyValue: this.inputItem.id,
          newValue
        });
      }
    }
  };
</script>

<style>
  .autostrategy-input {
    display: flex;

    justify-content: space-between;
    align-items: center;

    height: 100%;

    &.fullContainer_halfContent {
      .autostrategy-input__container {
        width: calc(50% - (var(--items-input-gap) / 2));
      }
    }

    &.halfContainer_fullContent,
    &.fullContainer_fullContent {
      .autostrategy-input__container {
        width: 100%;
      }
    }

    &.__switcher {
      .autostrategy-input__container {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }

      .autostrategy-input__label-container {
        margin-right: 16px;
        max-width: 463px;
      }

      .autostrategy-input__label {
        margin-bottom: 4px;
      }

      .autostrategy-input__label-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -1%;
        color: #242424;
      }
    }
  }

  .autostrategy-input__container {
    display: flex;

    flex-direction: column;
    flex-shrink: 0;
    justify-content: space-between;

    height: 100%;
  }

  .autostrategy-input__label {
    display: flex;

    align-items: center;

    margin-bottom: 8px;
  }

  .autostrategy-input__label-text {
    font-size: 14px;
    font-weight: 500;

    line-height: 16px;
  }

  .autostrategy-input__hint {
    margin-left: 4px;
  }

  .autostrategy-input__notify {
    height: 100%;

    margin-left: var(--items-input-gap);

    border-radius: 8px;

    &.autostrategy-input__notify-not-with-input {
      margin-left: 0;
    }
  }

  .autostrategy-input__label-description {
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -3%;
    color: #696969;
  }
</style>
