<template>
  <div class="autostrategy-form-block">
    <MErrorWrapper
      v-for="input in visibleInputList"
      :key="`${input.id}_${currentAutostrategy.id}`"
      :error-object="errors"
      :error-path="input.id"
      class="autostrategy-form-block__input"
      :class="getInputClass(input)"
    >
      <SellerAutostrategyInput
        :input-item="input"
        :value="values[input.id]"
        :channel-id="channelId"
        @change-value="handleChangeValue"
      />
    </MErrorWrapper>
  </div>
</template>

<script>
  import SellerAutostrategyInput from 'components/seller/campaings/SellerAutostrategyInput.vue';
  import MErrorWrapper from 'modules/common/components/MErrorWrapper/MErrorWrapper';
  import {fillFormInputItems} from 'utils/seller/autostrategy/sellerAutostrategyUtils';
  import {calculateComputed} from 'utils/object/objectTemplateCompiler';

  export default {
    name: 'SellerAutostrategyFormBlock',
    components: {
      SellerAutostrategyInput,
      MErrorWrapper
    },
    props: {
      currentAutostrategy: {
        type: Object,
        required: true
      },
      channelId: {
        type: [String, Number],
        required: true
      },
      errors: {
        type: Object,
        default: () => ({})
      },
      values: {
        type: Object,
        required: true
      }
    },
    computed: {
      fullInputItemList () {
        return fillFormInputItems(this.currentAutostrategy.arguments, this.channelId, this.currentAutostrategy.id);
      },
      visibleInputList () {
        let visibleArgs = [];
        for (const argument of this.fullInputItemList) {
          if (argument.visible === null) {
            visibleArgs.push(argument);
          } else {
            let computed = calculateComputed([{id: 'visible', calc: argument.visible}], this.values);
            if (computed.visible) {
              visibleArgs.push(argument);
            } else if ((argument.args || {}).min !== undefined) {
              this.handleChangeValue({
                keyValue: argument.id,
                newValue: argument.args.min
              });
            }
          }
        }
        return visibleArgs;
      }
    },
    methods: {
      getInputClass (input) {
        const classList = [];

        if (input.sizeContainer) {
          classList.push(input.sizeContainer);
        }

        return classList;
      },
      handleChangeValue (params) {
        this.$emit('change-value', params);
      }
    }
  };
</script>

<style>
  .autostrategy-form-block {
    --items-input-gap: 16px;

    display: grid;

    grid-template-columns: repeat(2, 1fr);
    gap: var(--items-input-gap);
  }

  .autostrategy-form-block__input {
    &.halfContainer_fullContent {
      grid-column: span 1;
    }

    &.fullContainer_halfContent,
    &.fullContainer_fullContent {
      grid-column: 1 / -1;
    }
  }
</style>
